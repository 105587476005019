import React from "react"
import { Link } from "gatsby"

import _ from 'lodash'

import "../styles/article-list.css"
// to display CategoryList component
// import CategoryList from "../components/category-list"

// To convert string into Title Case
function titleCase(str){
	str = str.toLowerCase().split(' ');
	let final = [ ];
	for(let  word of str){
		 final.push(word.charAt(0).toUpperCase()+ word.slice(1));
	}
  return final.join(' ')
}

const ArticleList = ({ data }) => (
    <div>
        {/* 
            If category list is needed to display then 
            add id="articleListContainerForArticleList" to this div
        */}
        <div> 
            { data.allMarkdownRemark.edges.map(edge => {
                const { frontmatter } = edge.node
                return (
                    <div key={ frontmatter.path } style={{ paddingBottom: `24px` }}>
                        <div style={{ paddingBottom: `5px` }}>
                            <Link id="linkArticleListTitle" to={ frontmatter.path }> 
                                { frontmatter.title }
                            </Link>
                        </div>
                        <div id="articleListDateCategory">
                            <div>
                                {frontmatter.publish}
                                <span style={{ color: `dodgerblue`}}>&nbsp;|&nbsp;</span>
                                Updated: { frontmatter.update }
                            </div>
                            <div>
                                <small># </small>
                                { frontmatter.category.map((eachCategory, index) => 
                                    <>
                                        <Link id="linkArticleListCategory"
                                            key={ `/${_.kebabCase(eachCategory)}/` }                                       
                                            to={ `/${_.kebabCase(eachCategory)}/` }
                                        >{ titleCase(eachCategory) }
                                        </Link>
                                        <small><span>{frontmatter.category[index+1] ? ` | ` : ``}</span></small>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
        {/* 
            If category list is needed to display then
            un-comment this div
         */}
        {/* <div id="articleListContainerForCategoryList"><CategoryList /></div> */}
    </div>
  )
  
  export default ArticleList

  // Ignore following words from checking by Code Spell Checker
// cSpell:ignore akinul jony frontmatter dodgerblue 